import * as React from "react";
import ScrollDown from "../common/scrolldown";

interface Prop {
  aboutSection?: any;
  homeSection?: any;
}

const Home = (props: Prop) => {
  return (
    <section
      id="hero"
      className="d-flex align-items-center"
      ref={props.homeSection}
    >
      <div className="container" data-aos="zoom-out" data-aos-delay="100">
        <h1>
          Welcome to <span>Claytronis</span>
        </h1>
        <h2>We are team of talanted developers provide software solutions</h2>
        <div className="d-flex">
          <li
            className="btn-get-started scrollto"
            onClick={() => ScrollDown(props.aboutSection)}
          >
            Get Started
          </li>
        </div>
      </div>
    </section>
  );
};

export default Home;
