import axios, { AxiosResponse } from "axios";
import * as zod from "zod";
import { PostRequest, PutRequest, GetRequest, RequestBase, Response, ProblemDetailsSchema, Api } from "./types";
import error from "./error";

export interface Settings {
  hosts?: string[] | null;
  baseUrl?: string;
  jwt?: string | null;
}

async function handleResponse<TParameters extends RequestBase<TResponse, Def>, TResponse, Def extends zod.ZodTypeDef = zod.ZodTypeDef>(
  parameters: TParameters,
  sendRequest: (host: string | null) => Promise<AxiosResponse>,
  hosts?: string[] | null
): Promise<{ response: Response<TResponse>; host: string | null }> {
  const result: Response<TResponse> = {
    url: parameters.url,
    success: false,
    error: "Unknown",
  };

  let host: string | null = null;
  let response: AxiosResponse | null = null;
  try {
    //Determine valid host from options:
    if (hosts && hosts.length > 0) {
      for (const h of hosts) {
        host = h;
        response = await sendRequest(host);
        if (response.status !== 0 && response.status === 503) break;
      }
    } else {
      response = await sendRequest(null);
    }
    if (!response) return { response: result, host: host };

    result.status = response.status;
    if ((typeof parameters.success === "number" && result.status === parameters.success) || (Array.isArray(parameters.success) && parameters.success.includes(result.status))) {
      try {
        result.data = parameters.responseSchema.parse(response.data);
        result.success = true;
        result.error = "Success";
        return { response: result, host: host };
      } catch (e) {
        result.error = "ResponseParseError";
        result.errorInfo = e;
      }
    } else {
      if (result.status === 400 || result.status === 500) {
        try {
          result.problemDetails = ProblemDetailsSchema.parse(response.data);
        } catch (e) {
          //ignore
        }
      }
      result.error = "StatusError";
    }
  } catch (e) {
    result.error = "ConnectionError";
    result.errorInfo = e;
  }
  parameters.error ??= error();
  parameters.error?.(result);
  return { response: result, host: host };
}

function validateStatus(status: number): boolean {
  return status >= 200 && status < 500;
}

export function createApi(settings: Settings): Api {
  let validHosts: string[] | null = null;
  return {
    post: async <TResponse, Def extends zod.ZodTypeDef = zod.ZodTypeDef>(parameters: PostRequest<TResponse, Def>): Promise<Response<TResponse>> => {
      const { response, host } = await handleResponse<PostRequest<TResponse, Def>, TResponse, Def>(
        parameters,
        () =>
          axios.post(parameters.url, parameters.data, {
            params: parameters.params,           
            headers: {
              Authorization: settings.jwt ? `Bearer ${settings.jwt}` : ''
            },
            baseURL: parameters.baseUrl ?? settings.baseUrl ?? "",
            validateStatus: validateStatus,
          }),
        validHosts ?? settings.hosts
      );
      if (host) validHosts = [host];
      return response;
    },
    put: async <TResponse, Def extends zod.ZodTypeDef = zod.ZodTypeDef>(parameters: PutRequest<TResponse, Def>): Promise<Response<TResponse>> => {
      const { response, host } = await handleResponse<PutRequest<TResponse, Def>, TResponse, Def>(
        parameters,
        () =>
          axios.put(parameters.url, parameters.data, {
            params: parameters.params,           
            headers: {
              Authorization: settings.jwt ? `Bearer ${settings.jwt}` : ''
            },
            baseURL: parameters.baseUrl ?? settings.baseUrl ?? "",
            validateStatus: validateStatus,
          }),
        validHosts ?? settings.hosts
      );
      if (host) validHosts = [host];
      return response;
    },
    get: async <TResponse, Def extends zod.ZodTypeDef = zod.ZodTypeDef>(parameters: GetRequest<TResponse, Def>): Promise<Response<TResponse>> => {
      const { response, host } = await handleResponse<GetRequest<TResponse, Def>, TResponse, Def>(
        parameters,
        () =>
          axios.get(parameters.url, {
            params: parameters.params,    
            headers: {
              Authorization: settings.jwt ? `Bearer ${settings.jwt}` : ''
            },       
            baseURL: parameters.baseUrl ?? settings.baseUrl ?? "",
            validateStatus: validateStatus,
          }),
        validHosts ?? settings.hosts
      );
      if (host) validHosts = [host];
      return response;
    },
    any: zod.any(),
  };
}

const api: Api = createApi({
  hosts: null,
  baseUrl: "/api",
  jwt: null,
});
export default api;