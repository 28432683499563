import React, { useState } from "react";
import styles from "../common/Common.module.css";
import contactus from "../../assets/img/contactus.png";
import { Button, Form, Input } from "antd";
import { sendMail } from "../api/index";
import { Collapse } from "react-collapse";

interface Prop {
  contactSection?: any;
}

export const MailStateFields = {
  name: "name",
  email: "email",
  message: "message",
  subject: "subject",
};

const Contact = (props: Prop) => {
  const [loading, setloading] = useState<boolean>(false);
  const [showError, setshowError] = useState<boolean>(false);
  const [showOk, setshowOk] = useState<boolean>(false);

  const [form] = Form.useForm();
  function initializeFormData() {
    form.setFieldsValue({
      [MailStateFields.name]: "",
      [MailStateFields.email]: "",
      [MailStateFields.message]: "",
      [MailStateFields.subject]: "",
    });
  }

  async function handleOnSubmit(values: any) {
    try {
      setloading(true);
      let response = await sendMail({
        body: `Details : <br />
               Customer Name  : ${values[MailStateFields.name]} <br />
               Customer Email : ${values[MailStateFields.email]} <br />
               Customer Note  : ${values[MailStateFields.message]}`,
        subject: `Enquiry: ${values[MailStateFields.subject]}`,
        toEmail: `${values[MailStateFields.email]}`,
        requestType: "E",
        customerName : `${values[MailStateFields.name]}`,
      });

      if (response) {
        setloading(false);
        setshowError(false);
        setshowOk(true);
        initializeFormData();
      } else {
        setloading(false);
        setshowError(true);
        setshowOk(false);
      }
      return response;
    } catch (e) {
      setloading(false);
      setshowError(true);
      setshowOk(false);
    }
    return false;
  }

  return (
    <section id="contact" className="contact" ref={props.contactSection}>
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <img src={contactus} className={styles.height50} alt="" />
          <h3>Contact Us</h3>
        </div>

        <div className="row" data-aos="fade-up" data-aos-delay="100">
          <div className="col-lg-6">
            <div className="info-box mb-4">
              <i className="bx bx-map"></i>
              <h3>Our Address</h3>
              <p>Noida, India</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="info-box  mb-4">
              <i className="bx bx-envelope"></i>
              <h3>Email Us</h3>
              <p>sales@claytronis.com</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="info-box  mb-4">
              <i className="bx bx-phone-call"></i>
              <h3>Call Us</h3>
              <p>+91 706 0031372/+91 989 1846270</p>
            </div>
          </div>
        </div>

        <div className="row" data-aos="fade-up" data-aos-delay="100">
          <div className="col-lg-6 ">
            <iframe
              title="Claytronis"
              className="mb-4 mb-lg-0"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d56089.370370188975!2d77.3521148388734!3d28.522111634659215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce5a43173357b%3A0x37ffce30c87cc03f!2sNoida%2C%20Uttar%20Pradesh!5e0!3m2!1sen!2sin!4v1606142661608!5m2!1sen!2sin"
              frameBorder={0}
              style={{ border: "0", width: "100%", height: "430px" }}
              allowFullScreen
            ></iframe>
          </div>

          <div className="col-lg-6">
            <Form
              form={form}
              name="mailForm"
              onFinish={handleOnSubmit}
              className="email-form"
            >
              <h6>
                We're glad you have chosen us to transform your ideas. We can’t
                wait to hear from you. Please share your thoughts and we’ll get
                back in touch shortly to you.{" "}
              </h6>
              <div className="form-row">
                <Form.Item
                  name={MailStateFields.name}
                  className="col form-group"
                  rules={[
                    {
                      required: true,
                      message: "Please input your name!",
                    },
                  ]}
                >
                  <Input placeholder={"Your Name"} className="form-control" />
                </Form.Item>
                <Form.Item
                  name={MailStateFields.email}
                  className="col form-group"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a valid email",
                    },
                  ]}
                >
                  <Input placeholder={"Your Email"} className="form-control" />
                </Form.Item>
              </div>
              <div className="form-row">
                <Form.Item
                  name={MailStateFields.subject}
                  className="col form-group"
                  rules={[
                    {
                      required: true,
                      message: "Please enter subject",
                    },
                  ]}
                >
                  <Input placeholder={"subject"} className="form-control" />
                </Form.Item>
              </div>
              <div className="form-row">
                <Form.Item
                  name={MailStateFields.message}
                  wrapperCol={{ offset: 8, span: 16 }}
                  className="col form-group"
                  rules={[
                    {
                      required: true,
                      message: "Please write something for us",
                    },
                  ]}
                >
                  <textarea placeholder="message" className="form-control" />
                </Form.Item>
              </div>
              <div>
                <Collapse isOpened={loading}>
                  <div className="loading">Loading</div>
                </Collapse>
                <Collapse isOpened={showError}>
                  <div className="error-message">
                    Error! Please send mail directly to sales@claytronis.com.
                  </div>
                </Collapse>
                <Collapse isOpened={showOk}>
                  <div className="sent-message">
                    Your message has been sent. Thank you!
                  </div>
                </Collapse>
              </div>
              <Form.Item
                wrapperCol={{ offset: 8, span: 16 }}
                className="text-center"
              >
                <Button type="primary" htmlType="submit">
                  Send Message
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
