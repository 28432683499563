import React from "react";
import styles from "../common/Common.module.css";
import serviceLogo from "../../assets/img/services.png";
import productLogo from "../../assets/img/services/product.png";
import productsLogo from "../../assets/img/services/products.png";
import marketingLogo from "../../assets/img/services/marketing.png";
import mobileLogo from "../../assets/img/services/mobile.png";
import webLogo from "../../assets/img/services/web.jpg";

interface Prop {
  serviceSection?: any;
}

const Services = (props: Prop) => {
  return (
    <section id="services" className="services" ref={props.serviceSection}>
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <img src={serviceLogo} className={styles.height50} alt="" />
          <h3>Our Services</h3>
          <p></p>
        </div>

        <div className="row">
          <div
            className="col-lg-4 col-md-6 d-flex align-items-stretch"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <div className="icon-box">
              <div className="icon">
                <img src={productLogo} className={styles.setwh} alt="" />
              </div>
              <div className="title">
                <p>
                  <span className={styles.top10}>Service</span>
                  <span>
                    <a href="# " className={styles.font20}>
                      Product Development
                    </a>
                  </span>
                </p>
              </div>
              <div className="description">
                <p>
                  <span>
                    Transform your ideas and adopting an intelligent approach to
                    bring your projects to life.
                  </span>
                  <span>
                    We help in the new product development process : concept
                    development, product development, <br />
                    product testing as well as maintenance <br />
                    of existing product.
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <div className="icon-box">
              <div className="icon">
                <img src={webLogo} className={styles.setwh} alt="" />
              </div>
              <div className="title">
                <p>
                  <span className={styles.top10}>Service</span>
                  <span>
                    <a href="# " className={styles.font20}>
                      Web Development
                    </a>
                  </span>
                </p>
              </div>
              <div className="description">
                <p>
                  <span>
                    We help you with our expert''s knowledge in <br /> website
                    designing/web applications with
                    <br /> the newest technologies to make them faster and use
                    latest tools to minimize the cost of the product.
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
            data-aos="zoom-in"
            data-aos-delay="300"
          >
            <div className="icon-box">
              <div className="icon">
                <img src={mobileLogo} className={styles.setwh} alt="" />
              </div>
              <div className="title">
                <p>
                  <span className={styles.top10}>Service</span>
                  <span>
                    <a href="# " className={styles.font20}>
                      Mobile Development
                    </a>
                  </span>
                </p>
              </div>
              <div className="description">
                <p>
                  <span>
                    A mobile app cover industries ranging <br />
                    from Real Estate, E-Commerce, Finance and <br />
                    many more. We are herein crafting custom mobile app for the
                    extensive business verticals with best development
                    standards.
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <div className="icon-box">
              <div className="icon">
                <img src={productsLogo} className={styles.setwh} alt="" />
              </div>
              <div className="title">
                <p>
                  <span className={styles.top10}>Service</span>
                  <span>
                    <a href="# " className={styles.font20}>
                      SQL Development
                    </a>
                  </span>
                </p>
              </div>
              <div className="description">
                <p>
                  <span>
                    Provides help to build Microsoft SQL <br /> database solutions
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <div className="icon-box">
              <div className="icon">
                <img src={marketingLogo} className={styles.setwh} alt="" />
              </div>
              <div className="title">
                <p>
                  <span className={styles.top10}>Service</span>
                  <span>
                    <a href="# " className={styles.font20}>
                      Digital Marketing
                    </a>
                  </span>
                </p>
              </div>
              <div className="description">
                <p>
                  <span>
                    We use the appropriate tools to ensure that your website is
                    at the top in search.
                    <br />
                    We provide SEO services for your website
                    <br /> marketing and help your business <br />
                    grow in a volatile market.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
