import React, { useEffect } from "react";
import Layout from "./components/layout/Layout";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "@fortawesome/react-fontawesome";
import "@fortawesome/free-solid-svg-icons";
import "./assets/css/style.css";
import "./assets/css/fontawesome-free/css/all.min.css";
import "./assets/js/main.js";
import "./assets/css/icofont/icofont.min.css";
import "./assets/css/boxicons/css/boxicons.min.css";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div className="App">
      <Layout></Layout>;
    </div>
  );
}

export default App;
