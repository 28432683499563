import React from "react";

const TopBar = () => {
  return (
    <div id="topbar" className="d-none d-lg-flex align-items-center fixed-top">
      <div className="container d-flex">
        <div className="contact-info mr-auto">
          <i className="icofont-envelope"></i>{" "}
          <a href="mailto:sales@claytronis.com">sales@claytronis.com</a>
          <i className="icofont-phone"></i> +91 98 918 46270
        </div>
        <div className="social-links">
          <a
            href="https://www.twitter.com"
            target="_blank"
            className="twitter"
            title="Twitter"
            rel="noreferrer"
          >
            <i className="icofont-twitter"></i>
          </a>
          <a
            href="https://www.facebook.com"
            target="_blank"
            className="facebook"
            title="Facebook"
            rel="noreferrer"
          >
            <i className="icofont-facebook"></i>
          </a>
          <a
            href="https://www.instagram.com"
            target="_blank"
            className="instagram"
            title="Instagram"
            rel="noreferrer"
          >
            <i className="icofont-instagram"></i>
          </a>
          <a
            href="https://www.skype.com"
            target="_blank"
            className="skype"
            title="Skype"
            rel="noreferrer"
          >
            <i className="icofont-skype"></i>
          </a>
          <a
            href="https://www.linkedin.com/in/claytronis-solutions-b5369a18b"
            target="_blank"
            className="linkedin"
            title="Linkedln"
            rel="noreferrer"
          >
            <i className="icofont-linkedin"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
