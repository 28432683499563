import logo from "../../assets/img/pexels-kozymeii-kong-950745.jpg";

interface Prop {
  aboutSection?: any;
}

const About = (props: Prop) => {
  return (
    <section id="about" className="about section-bg" ref={props.aboutSection}>
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <div className="aboutIcon fa-color">
            <i className="fas fa-broadcast-tower fa-2x"></i>
          </div>
          <h3>About Us</h3>
          <p>
            We provide companies software development expertise to build
            world-className softwares
          </p>
        </div>

        <div className="row">
          <div className="col-lg-6" data-aos="zoom-out" data-aos-delay="100">
            <img src={logo} className="img-fluid" alt="" />
          </div>
          <div
            className="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <h3>Our Mission</h3>
            <p
              className="font-italic"
              style={{ fontFamily: "'Strait', sans-serif !important" }}
            >
              To help any businesses build the right products in a
              cost-efficient way with our relevant expertise.
            </p>
            <ul>
              <li>
                <i
                  className="far fa-hand-rock"
                  style={{ color: "#4d1a00" }}
                ></i>
                <div>
                  <h4 style={{ fontFamily: "'Strait', sans-serif !important" }}>
                    Commitment
                  </h4>
                  <p style={{ fontFamily: "'Strait', sans-serif !important" }}>
                    Build the best products, use business to inspire and
                    implement solutions
                  </p>
                </div>
              </li>
              <li>
                <i
                  className="far fa-file-video"
                  style={{ color: "#4d1a00" }}
                ></i>
                <div>
                  <h4 style={{ fontFamily: "'Strait', sans-serif !important" }}>
                    Quality
                  </h4>
                  <p style={{ fontFamily: "'Strait', sans-serif !important" }}>
                    Develop a good relationship with customers and provide
                    outstanding products and services with values{" "}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
