import React from "react";
import styles from "../common/Common.module.css";
import pricing from "../../assets/img/pricing.png";

const Pricing = () => {
  return (
    <section id="pricing" className="pricing">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <img src={pricing} className={styles.height50} alt="" />
          <h3>Pricing</h3>
          <h4>Work together better with your growing team/Projects</h4>
        </div>

        <div className="row">
          <div className="col-lg-1"></div>
          <div
            className="col-lg-3 col-md-6 mt-4 mt-md-0"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="box featured">
              <h3>Fixed Price</h3>
              <h4>
                <sup>$</sup>4000<span> / month</span>
              </h4>
              <ul>
                <li></li>
                <li>Contact us for more information</li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
          </div>

          <div
            className="col-lg-3 col-md-6 mt-4 mt-lg-0"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="box">
              <h3>Hourly Priced</h3>
              <h4>
                <sup>$</sup>30<span> / Hour</span>
              </h4>
              <ul>
                <li></li>
                <li>Contact us for more information</li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
          </div>

          <div
            className="col-lg-3 col-md-6 mt-4 mt-lg-0"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <div className="box">
              <h3>Enterprise</h3>
              <h6>Custom Terms</h6>
              <ul>
                <li>
                  Please tell us about your project and your planned budget,{" "}
                  <br /> so we can work on <br />
                  specific info and <br />
                  on your needs.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
