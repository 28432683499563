import React from "react";
import styles from "../common/Common.module.css";
import faq from "../../assets/img/faq.png";

const Faq = () => {
  return (
    <section id="faq" className="faq section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <img src={faq} className={styles.height50} alt="" />
          <h3>Frequently Asked Questions</h3>
        </div>

        <ul className="faq-list" data-aos="fade-up" data-aos-delay="100">
          <li>
            <a data-toggle="collapse" className="collapsed" href="#faq1">
              What if I need help, Do you offer support?
              <i className="icofont-brand-lionix"></i>
            </a>
            <div id="faq1" className="collapse" data-parent=".faq-list">
              <p>
                Yes, we are happy to support you. Please contact us for more
                information.
              </p>
            </div>
          </li>

          <li>
            <a data-toggle="collapse" href="#faq2" className="collapsed">
              What are the technologies, you are using for Product Development?{" "}
              <i className="icofont-brand-lionix"></i>
            </a>
            <div id="faq2" className="collapse" data-parent=".faq-list">
              <p>
                We are using several technologies currently in development.
                Technologies & Languagaes : <br />
                latest .Net Framework, C#, Asp.Net, MVC framework, ReactJs,
                VueJs, AngularJS, Wep API Design Tool : Figma, Adobe XD
                Development Model : AGILE/SCRUM, TFS/GIT/BITBUCKET, Confluence
                In development, we are using latest concept and approach.
              </p>
            </div>
          </li>

          <li>
            <a data-toggle="collapse" href="#faq3" className="collapsed">
              How much does a web application/desktop application cost?
              <i className="icofont-brand-lionix"></i>
            </a>
            <div id="faq3" className="collapse" data-parent=".faq-list">
              <p>
                Please see the Pricing section and contact us for more
                information.
              </p>
            </div>
          </li>

          <li>
            <a data-toggle="collapse" href="#faq6" className="collapsed">
              Super, Now tell me what makes your company different?{" "}
              <i className="icofont-brand-lionix"></i>
            </a>
            <div id="faq6" className="collapse" data-parent=".faq-list">
              <p>
                It is knowledge and commitment. Both are very important things
                to complete a project successfully. Our IT experience gives us a
                unique ability to apply technology, in all its forms, to
                business process. We have extensive experience with technology
                replacement projects and IT process improvements. We have
                delivered large technology projects on time, on budget and in
                line with business strategy. Over the years, we have worked with
                reputed industry leaders such as Birlasoft, GE, Moreho, Sun
                Power, Dur Group and the manufacturing domain. Domains We have
                worked in Finance, Real Estate, Automobiles, Energy, Smart
                Cards.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Faq;
