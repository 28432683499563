import React from "react";
import styles from "../common/Common.module.css";
import team from "../../assets/img/team.png";
import member1 from "../../assets/img/team/avatar04.png";
import member5 from "../../assets/img/team/avatar5.png";

interface Prop {
  teamSection?: any;
}

const Team = (props: Prop) => {
  return (
    <section id="team" className="team" ref={props.teamSection}>
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <img src={team} className={styles.height50} alt="" />
          <h3>Meet Our Team</h3>
        </div>

        <div className="row">
          <div
            className="col-lg-3 col-md-6 d-flex justify-content-center"
            data-aos="fade-up"
            data-aos-delay="100"
          >           
            <div className="member">
              <div className="member-img">
                <img src={member1} className="img-fluid" alt="" />
                <div className="social">
                  <a href="# ">
                    <i className="icofont-twitter"></i>
                  </a>
                  <a href="# ">
                    <i className="icofont-facebook"></i>
                  </a>
                  <a href="# ">
                    <i className="icofont-instagram"></i>
                  </a>
                  <a href="# ">
                    <i className="icofont-linkedin"></i>
                  </a>
                </div>
              </div>
              <div className="member-info">
                <h4>Vikas</h4>
                <span>
                  Vikas is designer, programmer and Seo consultant. He has
                  worked with global companies for over 14 years.
                </span>
              </div>
            </div>
          </div>

          <div
            className="col-lg-3 col-md-6 d-flex justify-content-center"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="member">
              <div className="member-img">
                <img src={member5} className="img-fluid" alt="" />
                <div className="social">
                  <a href="# ">
                    <i className="icofont-twitter"></i>
                  </a>
                  <a href="# ">
                    <i className="icofont-facebook"></i>
                  </a>
                  <a href="# ">
                    <i className="icofont-instagram"></i>
                  </a>
                  <a href="# ">
                    <i className="icofont-linkedin"></i>
                  </a>
                </div>
              </div>
              <div className="member-info">
                <h4>Manu</h4>
                <span>
                  Manu is expert in developing desktop enterprise applications
                  in C#, VB.Net. He has experience over 12 years.
                </span>
              </div>
            </div>
          </div>

          {/* <div
            className="col-lg-3 col-md-6 d-flex align-items-stretch"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="member">
              <div className="member-img">
                <img src={member1} className="img-fluid" alt="" />
                <div className="social">
                  <a href="# ">
                    <i className="icofont-twitter"></i>
                  </a>
                  <a href="# ">
                    <i className="icofont-facebook"></i>
                  </a>
                  <a href="# ">
                    <i className="icofont-instagram"></i>
                  </a>
                  <a href="# ">
                    <i className="icofont-linkedin"></i>
                  </a>
                </div>
              </div>
              <div className="member-info">
                <h4>Ajay</h4>
                <span>
                  Ajay's career has many achievements but is driven by one goal;
                  To make customers happy with the software he create.
                  Experience : 10 years
                </span>
              </div>
            </div>
          </div>

          <div
            className="col-lg-3 col-md-6 d-flex align-items-stretch"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <div className="member">
              <div className="member-img">
                <img src={member5} className="img-fluid" alt="" />
                <div className="social">
                  <a href="# ">
                    <i className="icofont-twitter"></i>
                  </a>
                  <a href="# ">
                    <i className="icofont-facebook"></i>
                  </a>
                  <a href="# ">
                    <i className="icofont-instagram"></i>
                  </a>
                  <a href="# ">
                    <i className="icofont-linkedin"></i>
                  </a>
                </div>
              </div>
              <div className="member-info">
                <h4>Navneet</h4>
                <span>
                  An outstanding sharepoint administrator &#128512;, always
                  happy to offer his services to the customers 24*7. He works
                  with the customer to assist in creating strategy for
                  sharepoint online. Experience : 16 years
                </span>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Team;
