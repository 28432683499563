import React from "react";
import NavMenu from "./navmenu/NavMenu";

const Layout = () => (
  <React.Fragment>
    <NavMenu />
  </React.Fragment>
);

export default Layout;
