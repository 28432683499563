import React from "react";

const ServiceStandards = () => {
  return (
    <section id="featured-services" className="featured-services">
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
              <div className="icon">
                <i className="bx bxl-dribbble"></i>
              </div>
              <h4 className="title">
                <a href="# ">Developement</a>
              </h4>
              <p className="description"></p>
            </div>
          </div>

          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
              <div className="icon">
                <i className="bx bx-file"></i>
              </div>
              <h4 className="title">
                <a href="#Customer">Customer Centric Approach</a>
              </h4>
              <p className="description"></p>
            </div>
          </div>

          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
              <div className="icon">
                <i className="fas fa-tachometer-alt"></i>
              </div>
              <h4 className="title">
                <a href="#Speedy">Speedy Services</a>
              </h4>
              <p className="description"></p>
            </div>
          </div>

          <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
              <div className="icon">
                <i className="bx bx-world"></i>
              </div>
              <h4 className="title">
                <a href="#Consulting">Consulting</a>
              </h4>
              <p className="description"></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceStandards;
