const ScrollDown = (ref: any, h?: any) => {
  if (ref.current === null) {
    window.scrollTo(0, 0);
  } else {
    let i = h || 0;
    if (i < 8) {
      setTimeout(() => {
        window.scrollTo({
          top: ref.current.offsetTop - (65 + i),
          behavior: "smooth",
        });
        ScrollDown(ref, i + 1);
      }, 1);
    }
  }
};

export default ScrollDown;
