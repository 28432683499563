import React from "react";
import member1 from "../../assets/img/team/avatar04.png";
// import member2 from "../../assets/img/team/avatar.png";
import member3 from "../../assets/img/team/avatar5.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const Testimonials = () => {
  return (
    <section className="testimonials">
      <div className="container" data-aos="zoom-in">
        <div>
          <Carousel
            autoPlay
            // interval={5000}
            // transitionTime={5000}
            showThumbs={false}
            showArrows={false}
            showStatus={false}
            showIndicators={true}
            infiniteLoop={true}
          >
            <div className="testimonial-item">
              <img src={member1} className="testimonial-img" alt="" />
              <h3>Vikas Singhal</h3>
              <h4>Web Developer/SEO Expert</h4>
              <br />
            </div>

            {/* <div className="testimonial-item">
              <img src={member2} className="testimonial-img" alt="" />
              <h3>Vikas</h3>
              <h4>Web Developer/SEO Expert</h4>
            </div> */}

            <div className="testimonial-item">
              <img src={member3} className="testimonial-img" alt="" />
              <h3>Manu</h3>
              <h4>Application Developer</h4>
            </div>

            {/* <div className="testimonial-item">
              <img src={member2} className="testimonial-img" alt="" />
              <h3>Ajay</h3>
              <h4>Web Developer</h4>
            </div>

            <div className="testimonial-item">
              <img src={member3} className="testimonial-img" alt="" />
              <h3>Navneet</h3>
              <h4>Sharepoint Administrator</h4>
            </div> */}
          </Carousel>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
