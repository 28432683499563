import * as z from "zod";
import mailPost from "../api/post";
import api from "../../utilities/api";
import apiError from "../../utilities/api/error";

export async function sendMail(
  data: z.infer<typeof mailPost.response.data>
): Promise<boolean> {    
  const response = await api.post({
    url: "Mail/send",
    data: data,
    success: 200,
    responseSchema: mailPost.response.status,
    error: apiError(),
  });  
  if (response.success && response.data) {    
    console.log(response.data);
    return response.data.status;
  }
  return false;
}
