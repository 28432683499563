import { useState, useEffect, useRef } from "react";
import "./NavMenu.css";
import TopBar from "../../home/TopBar";
import Header from "../../home/Header";
import Home from "../../home/Home";
import ServiceStandards from "../../home/ServiceStandards";
import About from "../../about/About";
import Clients from "../../about/Clients";
import ClientStatistics from "../../about/ClientStatistics";
import Skills from "../../about/Skills";
import Services from "../../services/Services";
import Testimonials from "../../services/Testimonials";
// import Portfolio from "../../portfolio/Portfolio";
import Team from "../../team/Team";
import Pricing from "../../contact/Pricing";
import Faq from "../../contact/Faq";
import Contact from "../../contact/Contact";
import Footer from "../../contact/Footer";
import "../../../assets/css/fontawesome-free/css/all.min.css";

import GetDimension from "../../common/GetDimension";
import ScrollDown from "../../common/scrolldown";

const NavMenu = () => {
  const headerSection = useRef(null);
  const homeSection = useRef(null);
  const aboutSection = useRef(null);
  const serviceSection = useRef(null);
  const portfolioSection = useRef(null);
  const teamSection = useRef(null);
  const contactSection = useRef(null);
  const [visibleSection, setVisibleSection] = useState("Home");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sectionRefs = [
    { section: "Home", ref: homeSection },
    { section: "About", ref: aboutSection },
    { section: "Services", ref: serviceSection },
    { section: "Portfolio", ref: portfolioSection },
    { section: "Team", ref: teamSection },
    { section: "Contact", ref: contactSection },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const { height: headerHeight } = GetDimension(headerSection.current);
      const scrollPosition = window.scrollY + headerHeight;
      const selected = sectionRefs.find(({ section, ref }) => {
        const ele = ref.current;
        if (ele) {
          const { offsetBottom, offsetTop } = GetDimension(ele);
          return scrollPosition > offsetTop && scrollPosition < offsetBottom;
        }
        return false;
      });

      if (selected && selected.section !== visibleSection) {
        setVisibleSection(selected.section);
      } else if (!selected && visibleSection) {
        setVisibleSection("Home");
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sectionRefs, visibleSection]);

  return (
    <header>
      <TopBar />
      <Header
        headerSection={headerSection}
        homeSection={homeSection}
        aboutSection={aboutSection}
        serviceSection={serviceSection}
        portfolioSection={portfolioSection}
        teamSection={teamSection}
        contactSection={contactSection}
        visibleSection={visibleSection}
      />
      <Home aboutSection={aboutSection} homeSection={homeSection} />
      <main id="main">
        <ServiceStandards />
        <About aboutSection={aboutSection} />
        <Skills />
        <ClientStatistics />
        <Clients />
        <Services serviceSection={serviceSection} />
        <Testimonials />
        {/* <Portfolio portfolioSection={portfolioSection} /> */}
        <Team teamSection={teamSection} />
        <Pricing />
        <Faq />
        <Contact contactSection={contactSection} />
      </main>
      <Footer
        headerSection={headerSection}
        homeSection={homeSection}
        aboutSection={aboutSection}
        serviceSection={serviceSection}
        portfolioSection={portfolioSection}
        teamSection={teamSection}
        contactSection={contactSection}
        visibleSection={visibleSection}
      />
      <div id="preloader"></div>
      <a
        href="# "
        className="back-to-top"
        onClick={() => ScrollDown(homeSection, 5)}
      >
        <i className="icofont-simple-up"></i>
      </a>
    </header>
  );
};

export default NavMenu;
