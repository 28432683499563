import { ResponseInfo } from "./types";

interface Params {
  allow?: number[];
}

function error(parameters?: Params): (info: ResponseInfo) => void {
  const params = parameters ?? {};
  return async (info) => {
    if (info.status && params.allow?.includes(info.status)) return;   
  };
}

export default error;
