import React, { useState } from "react";
import styles from "../common/Common.module.css";
import joinus from "../../assets/img/joinus.png";
import { Form, Input } from "antd";
import { sendMail } from "../api/index";
import { Collapse } from "react-collapse";
import ScrollDown from "../common/scrolldown";
import IProp from "../home/IProp";

export const MailStateFields = {
  email: "email",
};

const Footer = (props: IProp) => {
  const [form] = Form.useForm();
  const [showOk, setshowOk] = useState<boolean>(false);

  async function handleOnSubmit(values: any) {
    try {
      let response = await sendMail({
        body: `Details : <br />    
               Customer Email : ${values[MailStateFields.email]}  <br />`,
        subject: `Subscription Request`,
        toEmail: `${values[MailStateFields.email]}`,
        requestType: "S",
        customerName: `${values[MailStateFields.email]}`,
      });

      if (response) {
        setshowOk(true);
      } else {
        setshowOk(false);
      }

      return response;
    } catch (e) {}
    return false;
  }

  const handleClick = (section: any) => {
    ScrollDown(section, 5);
  };

  return (
    <footer id="footer">
      <div className="footer-newsletter">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <img src={joinus} className={styles.height50} alt="" />
              <h4>Join Our Newsletter</h4>
              <Form
                form={form}
                name="mailForm"
                onFinish={handleOnSubmit}
                className="email-form"
              >
                <Form.Item name={MailStateFields.email}>
                  <Input type="email" name="email" />
                </Form.Item>
                <Form.Item>
                  <Input type="submit" value="Subscribe" />
                </Form.Item>
              </Form>
              <Collapse isOpened={showOk}>
                <div className="sent-message">
                  Your subscription has been added. Thank you!
                </div>
              </Collapse>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 footer-contact">
              <h3>
                Claytronis<span></span>
              </h3>
              <p>
                {" "}
                Noida
                <br /> India <br />
                <br />
                <strong>Phone:</strong> +91 989 1846270
                <br />
                <strong>Email:</strong> sales@claytronis.com
                <br />
              </p>
            </div>

            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <i className="bx bx-chevron-right"></i> <a href="# ">Home</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a
                    href="#about"
                    onClick={() => handleClick(props.aboutSection)}
                  >
                    About us
                  </a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a
                    href="#services"
                    onClick={() => handleClick(props.serviceSection)}
                  >
                    Services
                  </a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a
                    href="#team"
                    onClick={() => handleClick(props.teamSection)}
                  >
                    Team
                  </a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a href="# ">Terms of service</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a href="# ">Privacy policy</a>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a href="# ">Web Design</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a href="# ">Web Development</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a href="# ">Product Management</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a href="# ">Digital Marketing</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a href="# ">Search engine optimization</a>
                </li>
                {/* <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a href="# ">Sharepoint Administration</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a href="# ">Sharepoint Developement</a>
                </li> */}
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Our Social Networks</h4>
              <p></p>
              <div className="social-links mt-3">
                <a
                  href="https://www.twitter.com"
                  target="_blank"
                  className="twitter"
                  title="Twitter"
                  rel="noreferrer"
                >
                  <i className="icofont-twitter"></i>
                </a>
                <a
                  href="https://www.facebook.com"
                  target="_blank"
                  className="facebook"
                  title="Facebook"
                  rel="noreferrer"
                >
                  <i className="icofont-facebook"></i>
                </a>
                <a
                  href="https://www.instagram.com"
                  target="_blank"
                  className="instagram"
                  title="Instagram"
                  rel="noreferrer"
                >
                  <i className="icofont-instagram"></i>
                </a>
                <a
                  href="https://www.skype.com"
                  target="_blank"
                  className="skype"
                  title="Skype"
                  rel="noreferrer"
                >
                  <i className="icofont-skype"></i>
                </a>
                <a
                  href="https://www.linkedin.com/in/claytronis-solutions-b5369a18b"
                  target="_blank"
                  className="linkedin"
                  title="Linkedln"
                  rel="noreferrer"
                >
                  <i className="icofont-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container py-4">
        <div className="copyright">
          &copy; Copyright{" "}
          <strong>
            <span>Claytronis</span>
          </strong>
          . All Rights Reserved
        </div>
      </div>
    </footer>
  );
};

export default Footer;
