import React, { useState } from "react";
import ScrollDown from "../common/scrolldown";
import IProp from "./IProp";
import NavOptions from "./NavOptions";

const Header = (props: IProp) => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const handleToggle = (e: any) => {
    setToggleMenu(!toggleMenu);

    props.headerSection.current.classList.add("mobile-nav-active");
    if (!e.target.classList.contains("icofont-close")) {
      e.target.classList.remove("icofont-navigation-menu");
      e.target.classList.add("icofont-close");
    } else {
      e.target.classList.remove("icofont-close");
      e.target.classList.add("icofont-navigation-menu");
    }
  };

  return (
    <header id="header" className="fixed-top" ref={props.headerSection}>
      <div className="container d-flex align-items-center">
        <h1 className="logo mr-auto">
          <a href="#home1">Claytronis</a>
        </h1>
        <nav className="nav-menu d-none d-lg-block">
          <ul>
            <li
              className={props.visibleSection === "Home" ? "active" : undefined}
            >
              <a href="#home" onClick={() => ScrollDown(props.homeSection, 5)}>
                Home
              </a>
            </li>
            <li
              className={
                props.visibleSection === "About" ? "active" : undefined
              }
            >
              <a
                href="#about"
                onClick={() => ScrollDown(props.aboutSection, 5)}
              >
                About
              </a>
            </li>
            <li
              className={
                props.visibleSection === "Services" ? "active" : undefined
              }
            >
              <a
                href="#services"
                onClick={() => ScrollDown(props.serviceSection, 5)}
              >
                Services
              </a>
            </li>
            {/* <li
              className={
                props.visibleSection === "Portfolio" ? "active" : undefined
              }
            >
              <a
                href="#portfolio"
                onClick={() => ScrollDown(props.portfolioSection, 5)}
              >
                Portfolio
              </a>
            </li> */}
            <li
              className={props.visibleSection === "Team" ? "active" : undefined}
            >
              <a href="#team" onClick={() => ScrollDown(props.teamSection, 5)}>
                Team
              </a>
            </li>
            <li
              className={
                props.visibleSection === "Contact" ? "active" : undefined
              }
            >
              <a
                href="#contact"
                onClick={() => ScrollDown(props.contactSection, 5)}
              >
                Contact
              </a>
            </li>
          </ul>
        </nav>
        <button
          type="button"
          className="mobile-nav-toggle d-lg-none"
          onClick={handleToggle}
        >
          <i className="icofont-navigation-menu" style={{ color: "#FFF" }} />
        </button>
        {toggleMenu ? <NavOptions options={props} /> : ""}
      </div>
    </header>
  );
};

export default Header;
