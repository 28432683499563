import React, { useEffect, useState } from "react";
import IProp from "./IProp";
import ScrollDown from "../common/scrolldown";

interface IOptions {
  options: IProp;
}

const NavOptions = (props: IOptions) => {
  const [toggleMenuItem, setToggleMenuItem] = useState(
    props.options.visibleSection
  );
  useEffect(() => console.log("effect of" + toggleMenuItem), [toggleMenuItem]);

  const handleClick = (section: any, sectionName: string) => {
    setToggleMenuItem(sectionName);
    ScrollDown(section, 5);
  };

  return (
    <div className="mobile-nav drop-down d-lg-none">
      <ul>
        <li className={toggleMenuItem === "Home" ? "active" : undefined}>
          <a
            href="#home"
            onClick={() => handleClick(props.options.homeSection, "Home")}
          >
            Home
          </a>
        </li>
        <li className={toggleMenuItem === "About" ? "active" : undefined}>
          <a
            href="#about"
            onClick={() => handleClick(props.options.aboutSection, "About")}
          >
            About
          </a>
        </li>
        <li className={toggleMenuItem === "Services" ? "active" : undefined}>
          <a
            href="#services"
            onClick={() =>
              handleClick(props.options.serviceSection, "Services")
            }
          >
            Services
          </a>
        </li>
        {/* <li className={toggleMenuItem === "Portfolio" ? "active" : undefined}>
          <a
            href="#portfolio"
            onClick={() =>
              handleClick(props.options.portfolioSection, "Portfolio")
            }
          >
            Portfolio
          </a>
        </li> */}
        <li className={toggleMenuItem === "Team" ? "active" : undefined}>
          <a
            href="#team"
            onClick={() => handleClick(props.options.teamSection, "Team")}
          >
            Team
          </a>
        </li>
        <li className={toggleMenuItem === "Contact" ? "active" : undefined}>
          <a
            href="#contact"
            onClick={() => handleClick(props.options.contactSection, "Contact")}
          >
            Contact
          </a>
        </li>
      </ul>
    </div>
  );
};

export default NavOptions;
