import $ from "jquery";
window.$ = $;
window.jQuery = $.fn;

// Preloader
$(window).on("load", function () {
  if ($("#preloader").length) {
    $("#preloader")
      .delay(100)
      .fadeOut("slow", function () {
        $(this).remove();
      });
  }
});

// Toggle .header-scrolled class to #header when page is scrolled
$(window).scroll(function () {
  if ($(this).scrollTop() > 100) {
    $("#header").addClass("header-scrolled");
    $("#topbar").addClass("topbar-scrolled");
  } else {
    $("#header").removeClass("header-scrolled");
    $("#topbar").removeClass("topbar-scrolled");
  }
});

if ($(window).scrollTop() > 100) {
  $("#header").addClass("header-scrolled");
  $("#topbar").addClass("topbar-scrolled");
}

// Skills section
// $(".skills-content").waypoint(
//   function () {
//     $(".progress .progress-bar").each(function () {
//       $(this).css("width", $(this).attr("aria-valuenow") + "%");
//     });
//   },
//   {
//     offset: "80%",
//   }
// );

// Back to top button
$(window).scroll(function () {
  if ($(this).scrollTop() > 100) {
    $(".back-to-top").fadeIn("slow");
  } else {
    $(".back-to-top").fadeOut("slow");
  }
});

$(window).resize(function () {
  if ($(window).width() > 990) {
    if ($("body").hasClass("mobile-nav-active")) {
      $("body").removeClass("mobile-nav-active");
    }
    if ($(".mobile-nav-toggle i").hasClass("icofont-close")) {
      $(".mobile-nav-toggle i").toggleClass(
        "icofont-navigation-menu icofont-close"
      );
    }
  }
});
