import * as z from "zod";

const responseRow = z.object({
  body: z.string(),
  subject: z.string(),
  toEmail: z.string(),
  requestType: z.string(),
  customerName: z.string(),
});

const responseStatus = z.object({
  status: z.boolean(),
  errorMessage: z.string(),
});

const schema = {
  response: {
    data: responseRow,
    status: responseStatus,
  },
};

export default schema;
