import * as zod from "zod";

export type ErrorType = "Unknown" | "ResponseParseError" | "StatusError" | "ConnectionError" | "AuthorizationError" | "Success";

export type ErrorHandler = (info: ResponseInfo) => void;

export interface Api {
  post: <TResponse, Def extends zod.ZodTypeDef = zod.ZodTypeDef>(parameters: PostRequest<TResponse, Def>) => Promise<Response<TResponse>>,
  put: <TResponse, Def extends zod.ZodTypeDef = zod.ZodTypeDef>(parameters: PutRequest<TResponse, Def>) => Promise<Response<TResponse>>,
  get: <TResponse, Def extends zod.ZodTypeDef = zod.ZodTypeDef>(parameters: GetRequest<TResponse, Def>) => Promise<Response<TResponse>>,
  any: Zod.ZodAny
}

export interface ResponseInfo {
  success: boolean;
  error: ErrorType;
  url: string;
  status?: number;
  errorInfo?: any;
  problemDetails?: ProblemDetails;
}

export interface Response<TData> extends ResponseInfo {
  data?: TData;
}

export interface RequestBase<TResponse, Def extends zod.ZodTypeDef = zod.ZodTypeDef> {
  url: string;
  success: number | number[];
  responseSchema: zod.ZodType<TResponse, Def>;
  params?: any;
  baseUrl?: string;
  headers?: [string, string][];
  error?: ErrorHandler;
  checkUnauthorized?: boolean;
}

export interface PostRequest<TResponse, Def extends zod.ZodTypeDef = zod.ZodTypeDef> extends RequestBase<TResponse, Def> {
  data: any;
}

export interface PutRequest<TResponse, Def extends zod.ZodTypeDef = zod.ZodTypeDef> extends RequestBase<TResponse, Def> {
  data: any;
}

export interface GetRequest<TResponse, Def extends zod.ZodTypeDef = zod.ZodTypeDef> extends RequestBase<TResponse, Def> {}

export const ProblemDetailsSchema = zod.object({
  type: zod.string().optional().nullable(),
  title: zod.string().optional().nullable(),
  status: zod.number().optional().nullable(),
  detail: zod.string().optional().nullable(),
  instance: zod.string().optional().nullable(),
  errors: zod.record(zod.array(zod.string())).optional().nullable(),
}).nonstrict()
export type ProblemDetails = zod.infer<typeof ProblemDetailsSchema>;